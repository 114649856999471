.pool.card {

  .card-header {
    color: white !important;
    background-color: #8039db;
  }

  .card-body {
    color: black!important;
  }

  .card-footer {
    font-family: AvenirNext-Medium;
    color: black;
  }

  .row-apr {
    line-height: 2.25rem;
  }

  .row-tvl {
    line-height: 1.25rem;
  }

  .btn-apr {
    color: $azure-primary !important;
  }

  .btn-claim {
    width: 94px;
    border-radius:20px;
  }

  .btn-stake {
    font-size: 1.25rem;
    padding: 4px 10px;
    border-width: 2px;
    background-color: white!important;
    color: black!important;
  }

  .btn-details {
    color: white !important;
    font-family: AvenirNext, sans-serif;
  }

  .btn-white {
    background-color: white!important;
    color: black!important;
  }

  .form-control {
    //background: rgb(100, 100, 100)!important;
    border: 1px solid #8039db!important;
    //border-width: 0!important;
    border-left-width: 1px!important;
    border-radius: 5px!important;
    // border-top-left-radius: 20px!important;
    color: black!important;
    padding-right: 10px!important;
    padding-left: 10px!important;
  }

  #stake_input::placeholder {
    //color: rgba(255,255,255,.7);
    opacity: 1;
}

  .input-group > span {
    background: rgba(255,255,255,.6)!important;
    border-width: 0!important;
    border-right-width: 1px!important;
    border-bottom-right-radius: 20px!important;
    border-top-right-radius: 20px!important;
  }
}

.pool-alt.card {
  .card-header {
    color: white !important;
    background-color: #8039db;
  }
}