.sticky-wrapper {
  height: 70px !important;
}

.header .navbar {
  padding: 25px 0 0 !important;
}

.logo-degenHall {
  height: 40px;
}

.navbar .navbar-nav .nav-item .nav-link.active {
  font-weight: 600 !important;
}

.navbar-brand > img {
  height: 80px;
}

.navbar-toggler-icon {
  filter: brightness(0) invert(1)!important;
}

@media (max-width: 414px) {
  .navbar-brand > img {
    height: 50px;
  }

  .navbar-collapse {
    background-color: black;
    z-index: 999;
    text-align: center;
    padding-bottom: 20px;
  }
}

.navbar .navbar-nav .nav-item .nav-link.active,
.navbar .navbar-nav .nav-item .nav-link:hover {
  color: #ffffff !important;
}

.social-icon {
  height: 2rem!important;
  filter:  grayscale(1) brightness(2)
}

.navbar .navbar-nav .nav-item .nav-link {
  padding: 30px 0px !important;
  color: #fff !important;
  font-size: 15px !important;
  text-transform: none !important;
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap !important;
    flex-flow: row nowrap !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
}

.text-logo {
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 3pt;
  color: white;
}

.text-logo:hover {
  color: white;
}

.crypto_nav {
  background-color: #8039db!important;
}

.dropdown-item:hover {
  background-color: #1078ff!important;
  color: white!important;
}

.nav-text, a {
  font-weight: 700!important;
}

small {
  font-style: italic;
}