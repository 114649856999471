.card-block-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 100%;

  &.balance {
    color: $gray;
    background-color: $gray-light;
  }

  &.your-stake {
    color: $azure-primary;
    background-color: $azure-light;
  }

  &.total-staked {
    color: $pink;
    background-color: $pink-light;
  }
}

.card-icon {
  width: 24px;
  height: 24px;
  border-top-left-radius:20px;
  border-top-right-radius:20px;
}

.card-title {
  color: $gray;
  font-size: 11px;
  font-weight: 600;
  font-family: AvenirNext-DemiBold, sans-serif;
  letter-spacing: 1px;
  line-height: 2.18;
  margin-bottom: 0 !important;
}

.card-value {
  color: #10223b;
  font-size: 20px;
  font-weight: 600;
  font-family: AvenirNext-DemiBold, sans-serif;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .cards-container {
    margin-right: 0px;
    margin-left: 0px;
  }

  .card-container:first-child {
    padding-left: 0px;
  }

  .card-container:last-child {
    padding-right: 0px;
  }

}

.header > .btn-white {
  background-color: white!important;
  color: black!important;
  border-radius: 20px!important;
  min-width: 10rem;
}