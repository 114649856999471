.modal-stake {
  .modal-dialog {
    max-width: 350px !important;
  }

  .stake-token {
    line-height: 1rem !important;
  }

  .btn-percentage {
    padding: 4px 16px;
  }

  @media (min-width: 992px) {
    .modal-dialog-centered {
      margin-left: calc(((100% - 350px) / 2) + 128px) !important;
    }
  }
}