// .main-container {
//   padding-top: 2rem;
// }

.main-container {
    min-height: 80vh;
}

// @media (min-width: 992px) {
//   .main-container {
//     position: absolute;
//     top: 0;
//     left: 257px;
//     min-width: calc(100% - 257px);
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     align-content: center;
//     overflow-y: scroll;
//     padding: 0px;

//     .content {
//       width: 928px;
//       padding: 55px 0;
//       margin: 0 auto;
//     }
//   }
// }
