@import "./variables";
@import "custom";
@import "Sidebar";
@import "TopNavbar";
@import "MainContainer";
@import "CardsSection";
@import "PoolCard";
@import "RoiModal";
@import "StakeModal";
@import "ClaimModal";
@import "InsufficientBalanceModal";
@import "animations";
@import "Banner";

body {
  font-family: Avenir Next, Roboto, Helvetica Neue, sans-serif;
  // background-image: url('../assets/images/bg.jpeg');
  // background-size: cover;
  background-color: white;
}

.footer {
  background-color: #8039db;

  a {
    color: white;
    font-size: 0.8rem;
  }

  a:hover {
    color: white;
    text-decoration: underline;
  }

  span {
    font-size: 0.6rem;
    color: white;
  }
}

.bold {
  font-weight: bold;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-medium {
  font-family: AvenirNext-Medium;
}

.text-value {
  color: black !important;
}

.text-announcement {
  font-size: 20px;
  color: black!important;
}

.text-title {
  font-size: 20px;
}

.text-desc {
  color: rgba(0,0,0,.9)!important;
  font-size: 14px;
  font-weight: 500;
}

.text-primary {
  color: $azure-primary !important;
}

.text-secondary {
  color: $azure-secondary !important;
}

.text-disabled {
  color: $gray-disabled;
}

input.border-none {
  text-align: right;
  border: none;
  padding: 0;
  box-shadow: none !important;
}

svg.icon {
  width: 16px;
  height: 16px;
}

h1.page-title {
  color: $blue-dark !important;
  font-weight: 500 !important;
  text-align: center;
}

@media (min-width: 992px) {

  .ref-input {
    font-size: 12px;
    border-color: transparent!important;
    min-width: 300px!important;
    //width: 380px!important;
    border-radius: 20px!important;
  }
  
}

@media (max-width: 720px) {
  body {
    font-size: 0.85rem!important;
  }
}

.round-body {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}



.alt-pool.card {

  // color: #101617;
  border: none !important;
  background: transparent;
  box-shadow: 0px 0px 20px 2px rgba(255,255,255,.4);


  .card-header {
    color: $white !important;
    background-color: black !important;
    font-family: AvenirNext-Medium;
    border-color: transparent !important;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
  }

  .card-body {
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    border: 1px solid #00000020;
    border-bottom: none !important;
    background-color: rgba(0,0,0,.8)!important;
    color: rgba(255,255,255,.75)!important;
    
  }

  .card-footer {
    font-family: AvenirNext-Medium;
    border: 1px solid #00000020;
    border-bottom-left-radius:20px;
    border-bottom-right-radius:20px;
    color: rgba(255,255,255,.75)!important;
    background-color: rgba(0,0,0,.8)!important;
  }

  .row-apr {
    line-height: 2.25rem;
  }

  .row-tvl {
    line-height: 1.25rem;
  }

  .btn-apr {
    color: $azure-primary !important;
  }

  .btn-claim {
    width: 94px;
    border-radius:20px;
  }

  .btn-stake {
    font-size: 1.25rem;
    padding: 4px 10px;
    border-width: 2px;
    background-color: white!important;
    color: black!important;
  }

  .btn-details {
    color: white !important;
    font-family: AvenirNext, sans-serif;
  }
  .btn-primary{
    border-radius: 20px!important;
  }

  .btn-white {
    background-color: white!important;
    color: black!important;
  }

  .form-control {
    background: rgba(255,255,255,.6)!important;
    border: 1px solid black!important;
    border-width: 0!important;
    border-left-width: 1px!important;
    border-bottom-left-radius: 20px!important;
    border-top-left-radius: 20px!important;
    color: white!important;
    padding-right: 10px!important;
    padding-left: 10px!important;
  }

  .input-group > span {
    background: rgba(255,255,255,.6)!important;
    border-width: 0!important;
    border-right-width: 1px!important;
    border-bottom-right-radius: 20px!important;
    border-top-right-radius: 20px!important;
  }
}