/* src/components/Banner.css */

.banner {
    width: 100vw;
    background-color: rgba(76, 0, 175, .7);
    text-align: center;
    font-size: 1rem;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    height: 5vh;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.scrolling-text {
    display: inline-block;
    padding-left: 100%;
    animation: scroll-text 10s linear infinite;
}

@keyframes scroll-text {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
